<template>
  <div class="absolute right-0 top-12 z-50 min-w-[10rem] bg-white shadow-lg rounded-lg">
    <ul class="flex flex-col">
      <li>
        <div
          class="flex items-center gap-2 text-[#474d66] px-4 py-3 hover:text-[#dd5928] hover:bg-[#f9e0d7] hover:border-l-[5px] border-[#dd5928] cursor-pointer rounded-xl"
          @click="viewEmployee"
          @mouseenter="toggleHover('profile', true)"
          @mouseleave="toggleHover('profile', false)"
        >
          <ProfileIcon :fill-color="hoverState.profile ? '#dd5928' : '#2D2D2E'" />
          My Profile
        </div>
      </li>
      <li>
        <div
          class="flex items-center gap-2 text-[#474d66] px-4 py-3 hover:text-[#dd5928] hover:bg-[#f9e0d7] hover:border-l-[5px] border-[#dd5928] cursor-pointer rounded-xl"
          @click="userLogout"
          @mouseenter="toggleHover('logout', true)"
          @mouseleave="toggleHover('logout', false)"
        >
          <LogoutIcon :fill-color="hoverState.logout ? '#dd5928' : '#2D2D2E'" />
          Logout
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useAccessStore } from "@/store/storeLeavesPermissions";
import { encodeUrlPath } from "@/helpers/utils";
import { logout } from "@/services/auth";
import { useStore } from "@/store/index";
import ProfileIcon from "@/assets/ProfileIcon.vue";
import LogoutIcon from "@/assets/LogoutIcon.vue";
import { HoverState } from "@/types/configTypes";

const store = useStore();
const Access = useAccessStore();
const env = process.env;
const hoverState = ref<HoverState>({
  profile: false,
  logout: false,
});
const toggleHover = (item: keyof HoverState, state: boolean) => {
  hoverState.value[item] = state;
};
const userLogout = () => {
  Access.$reset();
  logout().then(() => {
    window.location.href = `${env.VUE_APP_SSO}/logout?login-hint=${store?.loginHint}&account=${store?.user?.email}&redirect-url=${env.VUE_APP_MAIN_PAGE_URL}login`;
  });
};

const viewEmployee = () => {
  let encryptedId = encodeUrlPath(store?.user?.user_id?.toString());
  let hostedURL = env.VUE_APP_EMPLOYEE_URL;
  location.href = `${hostedURL}view-employee/${encryptedId}/personal`;
};
</script>
